// ------------------- buttons
.primary-button {
  text-align: center;
  padding: 20px 0;

  a {
    height: 70px;
    width: 300px;

    display: grid;
    justify-content: center;
    align-items: center;

    background-color: $background-colour;
    color: $primary-colour;

    border-style: solid;
    border-radius: 10px;
    border-color: $light-green;
    border-width: 5px;

    &:hover {
      text-decoration: none;
      font-style: bold;
      font-size: 15px;
      border-width: 6px;
    }
  }
}

.giant-button {
  // fill space
  flex: 1;
  position: relative;

  // center align text content
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 60px;
  @media screen and (max-width: $mobile-max-width) {
    font-size: 24px;
  }

  min-width: min(400px, 100%);
  padding: 20px;

  color: black;
  background-color: $translucent-hightlight-background-colour;
  opacity: 0.4;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
    color: $primary-colour;
  }
}

.button-silhouette {
  position: absolute;
  inset: 10px;
}

.giant-button-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
}
