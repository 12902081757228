a {
  color: black;
  text-decoration: none;
}

.wize-game-root {
  max-width: unset;
  display: grid;
  grid-template-rows: auto 1fr;
}

.game-title {
  font-size: 20pt;
  font-family: monospace;
  color: white;
  text-decoration: none;
}

.wize-game-component {
  display: grid;
  grid-template-rows: auto 1fr;
}

.wize-game-controls {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 10px;
}

.wize-game {
  text-align: center;
  height: 100%;
}

/* Weird things */

svg {
  position: absolute;
  overflow: hidden;
}

polygon {
  overflow: hidden;
}

img.left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

canvas.left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.game-canvas {
  width: 85%;
  aspect-ratio: 2 / 1;
}
