// variables
$mobile-max-width: 600px;

$primary-colour: rgb(55, 71, 32);
$secondary-colour: #e79d50;

$primary-background-colour: #606c38;
$background-colour: #fefae0;

$light-green: rgba(92, 119, 54, 0.8);
$dark-green: rgba(30, 39, 18, 0.8);

$translucent-background-colour: rgb(55, 71, 32, 0.7);
$translucent-hightlight-background-colour: #fefae088;

$text-colour: #fefae0;
$heading3-colour: rgb(200, 200, 200);

$theme-colors: (
  'primary': $primary-colour,
  'secondary': $secondary-colour,
  'danger': red
);

$linked-in-blue: #0b66c2;
