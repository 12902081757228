.personal-home {
  text-align: center;
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  gap: 20px;
}

.wize-info {
  img {
    max-height: 300px;
    max-width: 600px;
    width: 100%;
  }

  .play-now-button {
    align-self: center;
  }
}

.wize-game-modes-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.photography {
  img {
    max-width: 1000px;
    width: 100%;
    height: 500px;
    object-fit: scale-down;

    @media screen and (max-width: $mobile-max-width) {
      height: 400px;
    }
  }
}

.music {
  .intro-text {
    p {
      margin: 10px 0;
    }
  }

  .songs {
    p {
      margin: 10px 0;
    }
  }

  .song {
    iframe {
      max-width: 475px;
      width: 100%;
    }
    .visualAudible__artworkOverlay {
      display: none !important;
      height: 0px;
      width: 0px;
    }
  }
}
