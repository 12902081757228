.title {
  color: $secondary-colour;
  line-height: 1em;
  margin: 10px 0;

  font-size: 72px;

  @media screen and (max-width: $mobile-max-width) {
    font-size: 36px;
  }
}

.subtitle {
  font-size: 24px;
  @media screen and (max-width: $mobile-max-width) {
    font-size: 20px;
  }
}

.heading1 {
  font-size: 24px;
}

.heading2 {
  font-size: 20px;
}

.heading3 {
  font-size: 14px;
  font-style: italic;
  color: $heading3-colour;
}
