@import './variables';

.background {
  background-image: url('../resources/home/osaka-castle.jpeg');
  /* Add the blur effect */
  filter: blur(12px);
  -webkit-filter: blur(12px);

  /* Fill entire window */
  position: fixed;
  z-index: -1;

  width: 100%;
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.hide-after::after {
  display: none !important;
}

.app-container {
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: $text-colour;

  p {
    margin: 0;
  }
}

.app-content {
  height: 100%;
  display: grid;
  // header body footer
  grid-template-rows: auto 1fr auto;
}

.content-page-root {
  background-color: $translucent-background-colour;
  padding: 20px;
  display: flex;
  justify-content: center;
  height: 100%;
}

.content-page-internal {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.footer {
  color: black;
  background-color: $translucent-hightlight-background-colour;
  padding: 10px;
  text-align: center;
}

@import './text';
@import './graphics';
@import './buttons';
@import './navbar';
@import './silhouettes';

@import './home';
@import './wize';
@import './professional-home';
@import './personal-home';

@import '~bootstrap/scss/bootstrap';
