.silhouette {
  height: 100%;
  width: 100%;
  display: grid;
  fill: $primary-colour;
  fill-opacity: 0.3;

  svg {
    height: 100%;
    width: 100%;
  }
}
