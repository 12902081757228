// ------------------ graphics

.line {
  width: 100px;
  height: 6px;
  margin: 10px 0;
  background-color: $secondary-colour;
}

.small-line {
  width: 50px;
  height: 4px;
  margin: 5px 0;
  background-color: $secondary-colour;
}
